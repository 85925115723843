// landingPage.jsx
import React, { useState, useEffect } from 'react';
import './LandingPage.css';

const LandingPage = () => {
  const [selectedFeature, setSelectedFeature] = useState(0); // Default to first feature selected
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [role, setRole] = useState('');
  const [additionalDetails, setAdditionalDetails] = useState('');
  const [submissionMessage, setSubmissionMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isHeaderScrolled, setIsHeaderScrolled] = useState(false);

  const features = [
    {
      icon: "🤖",
      title: "AI-Assisted Idea Generation",
      description: "Transform rough concepts into polished proposals with our AI assistant."
    },
    {
      icon: "📋",
      title: "Seamless Idea Review Process",
      description: "Streamline how managers evaluate and provide feedback on employee ideas."
    },
    {
      icon: "🌀",
      title: "Innovation Challenges & Company Portal",
      description: "Focus creative energy on key business problems with custom challenges."
    },
    {
      icon: "🎮",
      title: "Gamification & Reward System",
      description: "Drive engagement with points, badges, and meaningful recognition."
    }
  ];

  const products = [
    {
      name: "For Employees",
      title: "AI-Assisted Idea Generation (Harmoni AI Helper)",
      description: "Transform your unstructured 'brain dumps' into coherent project proposals. Harmoni's AI refines rough concepts into actionable templates—so every idea starts on solid ground.",
      image: "Employee-Submit-Project-Idea.png"
    },
    {
      name: "For Managers",
      title: "Seamless Idea Review Process & Manager View",
      description: "Managers receive project ideas from their teams, provide feedback & ideas to the employee, and decide whether to approve, revise, or shelve—keeping the path from concept to execution clear and efficient.",
      image: "Manager-Team-Submissions-Menu.png"
    },
    {
      name: "For Teams, Manager and Employees",
      title: "Innovation Challenges & Company Portal",
      description: "Focus creative energy on key business problems with custom challenges. Keep everyone aligned through a centralized portal featuring real-time updates, announcements, and a shared hub for collaboration.",
      image: "Innovation-Challenge-Company.png"
    },
    {
      name: "For Teams, Managers and Employees",
      title: "Gamification & Reward System",
      description: "Drive engagement with points, badges, and leaderboards. Recognize top contributors, celebrate achievements, and foster a culture where every breakthrough is rewarded.",
      image: "Employee-Innovation-Reward.png"
    }
  ];

  const _plans = [
    {
      name: "Free",
      description: "Give us a try for free",
      price: 0,
      features: [
        "1 seat",
        "1 active project",
        "Unlimited viewers",
        "10 ideas"
      ]
    },
    {
      name: "Starter",
      description: "Best for small teams",
      price: 29,
      features: [
        "5 seats",
        "10 active projects",
        "Unlimited viewers",
        "100 ideas",
        "Analytics dashboard",
        "Priority support"
      ]
    },
    {
      name: "Premium",
      description: "For innovation-driven companies",
      price: 79,
      features: [
        "Unlimited seats",
        "Unlimited projects",
        "Unlimited viewers",
        "Unlimited ideas",
        "Advanced analytics",
        "Custom integrations",
        "Dedicated success manager"
      ]
    }
  ];

  const featureItems = [
    {
      icon: "🧠",
      title: "Transform Unstructured Thoughts",
      description: "Harmoni's AI refines rough ideas into polished proposals that can be pitched for implementation—helping you turn brainstorms into real opportunities."
    },
    {
      icon: "🏆",
      title: "Earn Rewards & Recognition", 
      description: "Get acknowledged for every valuable contribution. With transparent rewards, you'll always know what you'll receive for innovative ideas."
    },
    {
      icon: "🎭",
      title: "Anonymous Idea Submissions",
      description: "All project ideas are submitted privately to eliminate bias during review and iteration, ensuring merit-based decisions."
    },
    {
      icon: "💡",
      title: "Cultivate an Innovative Culture",
      description: "Encourage employees to think big and drive continuous improvements throughout the organization."
    },
    {
      icon: "⚡",
      title: "Receive Targeted Solutions",
      description: "Company executives launch 'Innovation Challenges,' and your top minds submit ideas to solve them for your review."
    },
    {
      icon: "📈",
      title: "Drive Measurable ROI",
      description: "Turn employee ideas into concrete projects, boosting your competitive advantage while maximizing returns on talent."
    },
    {
      icon: "⭐",
      title: "Receive Rewards & Recognition",
      description: "Harmoni ensures that high-performing managers are visible and receive pre-determined rewards as well as recognition in company-wide leaderboards."
    },
    {
      icon: "✅",
      title: "Quick and Easy Project Idea Reviews",
      description: "Refine and approve ideas in a seamless interface—provide notes, request revisions, and track decisions with ease."
    },
    {
      icon: "🎯",
      title: "Set Team Innovation Challenges",
      description: "Use your insight into team needs to create targeted 'Innovation Challenges,' spurring solutions that directly address department goals."
    }
  ];

  const testimonials = [
    {
      role: "HR Manager",
      company: "Insurance Company",
      quote: "We've never had such an easy way to collect and evaluate employee ideas. Even at this early stage, teams are more engaged and excited about contributing.",
      rating: 5
    },
    {
      role: "Research Department Head",
      company: "University Research Institute",
      quote: "Our managers appreciate the AI-assisted review flow, and researchers love the easy submission process. Harmoni has transformed how we approach innovation.",
      rating: 5
    },
    {
      role: "Startup Founder",
      company: "Fintech Startup",
      quote: "We've already seen employees more willing to submit ideas. Harmoni's streamlined process has us excited for the full launch. This is exactly what we needed.",
      rating: 5
    }
  ];

  // Handle header scroll effect
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsHeaderScrolled(true);
      } else {
        setIsHeaderScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Set initial feature selection
  useEffect(() => {
    setSelectedFeature(0);
  }, []);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEarlyAccess = async (e) => {
    e.preventDefault();
    if (!isValidEmail(email)) {
      setSubmissionMessage('Please enter a valid email address');
      return;
    }
    if (!firstName || !lastName || !email || !company || !role) {
      setSubmissionMessage('Please fill in all required fields');
      return;
    }
    setIsSubmitting(true);
    setSubmissionMessage('');
    
    try {
      const formData = {
        firstName,
        lastName,
        email,
        company,
        role,
        additionalDetails
      };

      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';
      const response = await fetch(`${API_BASE_URL}/api/email-documents/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        setSubmissionMessage('Thank you for your interest! Check your email for further details.');
        // Clear form
        setFirstName('');
        setLastName('');
        setEmail('');
        setCompany('');
        setRole('');
        setAdditionalDetails('');
      } else {
        const errorData = await response.json();
        setSubmissionMessage(errorData.error || 'Something went wrong. Please try again.');
      }
    } catch (error) {
      setSubmissionMessage('An error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleNavClick = (e, sectionId) => {
    e.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleFeatureClick = (index) => {
    setSelectedFeature(index);
  };

  return (
    <div className="landing-page">
      <header className={`header ${isHeaderScrolled ? 'scrolled' : ''}`}>
        <div className="logo">
          <img src="Logo.png" alt="Harmoni Logo" className='logo-image' />
          Harmoni
        </div>
        <nav className="nav">
          <a 
            href="#features" 
            className="nav-item" 
            onClick={(e) => handleNavClick(e, 'features')}
          >
            Features
          </a>
          <a 
            href="#benefits" 
            className="nav-item" 
            onClick={(e) => handleNavClick(e, 'benefits')}
          >
            Benefits
          </a>
          <a 
            href="#testimonials" 
            className="nav-item" 
            onClick={(e) => handleNavClick(e, 'testimonials')}
          >
            Testimonials
          </a>
          <button 
            className="sign-up-button" 
            onClick={(e) => handleNavClick(e, 'early-access')}
          >
            Get Early Access
          </button>
        </nav>
      </header>

      <main className="main-content">
        <div className="left-section">
          <div className="tagline">ACCELERATE INNOVATION</div>
          <h1 className="main-heading">Transform Employee Ideas Into Business Growth</h1>
          <p className="description">
          Harmoni helps you harness your team's collective intelligence to boost innovation, 
          improve efficiency, and gain a competitive edge. Transform employees' ideas into real 
          business impact with our AI-powered platform.
          </p>
          <div className="hero-buttons">
            <button 
              className="cta-button primary" 
              onClick={(e) => handleNavClick(e, 'early-access')}
            >
              Get Started Free
            </button>
            <button 
              className="cta-button secondary" 
              onClick={(e) => handleNavClick(e, 'features')}
            >
              See How It Works
            </button>
          </div>
          <div className="social-proof">
            <p>Trusted by innovative teams in:</p>
            <div className="company-logos">
              <span>FinTech</span>
              <span>Insurance</span>
              <span>Education</span>
            </div>
          </div>
        </div>
        <div className="right-section">
          <div className="hero-image-container">
            <img className="image main-image" src="Innovation-Lightbulb.png" alt="Innovation Platform" />
            <div className="floating-stat stat-1">
              <span className="stat-number">87%</span>
              <span className="stat-text">Increase in employee engagement</span>
            </div>
            <div className="floating-stat stat-2">
              <span className="stat-number">3x</span>
              <span className="stat-text">More ideas implemented</span>
            </div>
          </div>
        </div>
      </main>

      <div id="testimonials" className="section-header">
        <div className="tag">EARLY FEEDBACK FROM OUR PILOT USERS</div>
        <div className="testimonials-section">
          <div className="testimonials-grid">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="testimonial-card">
                <div className="testimonial-header">
                  <div className="testimonial-info">
                    <h3 className="testimonial-role">{testimonial.role}</h3>
                    <p className="testimonial-company">{testimonial.company}</p>
                  </div>
                </div>
                <div className="testimonial-rating">
                  {"★".repeat(testimonial.rating)}
                </div>
                <p className="testimonial-quote">{testimonial.quote}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div id="roi" className="roi-section">
        <div className="section-header">
          <div className="tag">BUSINESS IMPACT</div>
          <h2>Measurable Results for Your Business</h2>
        </div>
        <div className="metrics-container">
          <div className="metric-card">
            <div className="metric-value">87%</div>
            <div className="metric-label">Increase in employee engagement</div>
            <div className="metric-description">
              Employees feel more valued when their ideas are heard and implemented.
            </div>
          </div>
          <div className="metric-card">
            <div className="metric-value">3x</div>
            <div className="metric-label">More ideas implemented</div>
            <div className="metric-description">
              AI-assisted refinement helps turn rough concepts into actionable projects.
            </div>
          </div>
          <div className="metric-card">
            <div className="metric-value">40%</div>
            <div className="metric-label">Reduction in implementation time</div>
            <div className="metric-description">
              Streamlined review process gets ideas from concept to execution faster.
            </div>
          </div>
          <div className="metric-card">
            <div className="metric-value">$2.1M</div>
            <div className="metric-label">Average annual savings</div>
            <div className="metric-description">
              For mid-sized companies through efficiency improvements and innovation.
            </div>
          </div>
        </div>
        <div className="roi-cta">
          <p>Calculate your potential ROI with Harmoni</p>
          <button 
            className="cta-button" 
            onClick={(e) => handleNavClick(e, 'early-access')}
          >
            Get a Custom ROI Report
          </button>
        </div>
      </div>

      <div id="benefits" className="features-grid-section">
        <div className="feature-selection">
          <div className="section-header">
            <div className="tag-benefits">BENEFITS</div>
            <h2>How Harmoni Benefits Your Organization</h2>
          </div>
        </div>
        <div className="features-columns">
          <div className="feature-column">
            <h2 className="column-header">For Employees</h2>
            {featureItems.slice(0, 3).map((item, index) => (
              <div key={index} className="benefit-item">
                <div className="benefit-header">
                  <div className="benefit-icon">{item.icon}</div>
                  <h3>{item.title}</h3>
                </div>
                <p>{item.description}</p>
              </div>
            ))}
          </div>
          <div className="feature-column">
            <h2 className="column-header">For Companies</h2>
            {featureItems.slice(3, 6).map((item, index) => (
              <div key={index + 3} className="benefit-item">
                <div className="benefit-header">
                  <div className="benefit-icon">{item.icon}</div>
                  <h3>{item.title}</h3>
                </div>
                <p>{item.description}</p>
              </div>
            ))}
          </div>
          <div className="feature-column">
            <h2 className="column-header">For Managers</h2>
            {featureItems.slice(6, 9).map((item, index) => (
              <div key={index + 6} className="benefit-item">
                <div className="benefit-header">
                  <div className="benefit-icon">{item.icon}</div>
                  <h3>{item.title}</h3>
                </div>
                <p>{item.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div id="features" className="feature-selections-wrapper">
        <div className="feature-selection">
          <div className="section-header">
            <div className="tag">FEATURES</div>
            <h2>Discover How Harmoni Works</h2>
          </div>          
          <div className="feature-container">
            <div className="feature-list">
              {features.map((feature, index) => (
                <div
                  key={index}
                  className={`feature-item ${selectedFeature === index ? 'selected' : ''}`}
                  onClick={() => handleFeatureClick(index)}
                >
                  <div className="feature-icon">{feature.icon}</div>
                  <div className="feature-text">
                    <h3>{feature.title}</h3>
                    <p>{feature.description}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className={`product-display ${selectedFeature !== null ? 'visible' : ''}`}>
              <div className="product-header">
                <span>{products[selectedFeature].name}</span>
              </div>
              <h2>{products[selectedFeature].title}</h2>
              <p>{products[selectedFeature].description}</p>
              <img src={products[selectedFeature].image} alt="Product screenshot" className="product-image" />
            </div>
          </div>
        </div>
      </div>

      <div id="early-access" className="feature-selection">
        <div className="section-header">
          <div className="tag">GET STARTED</div>
          <h2>Join Our Early Access Program</h2>
          <p className="section-description">
            Be among the first to experience Harmoni and help shape the future of innovation management.
          </p>
        </div>
        <div className="early-access-container">
          <div className="early-access-content">
            <div className="early-access-benefits">
              <h3>Early Access Benefits</h3>
              <ul className="benefits-list">
                <li>
                  <span className="benefit-icon">✓</span>
                  <span>Priority onboarding and setup assistance</span>
                </li>
                <li>
                  <span className="benefit-icon">✓</span>
                  <span>30% discount on your first year subscription</span>
                </li>
                <li>
                  <span className="benefit-icon">✓</span>
                  <span>Direct access to our product team</span>
                </li>
                <li>
                  <span className="benefit-icon">✓</span>
                  <span>Influence future feature development</span>
                </li>
                <li>
                  <span className="benefit-icon">✓</span>
                  <span>Free implementation consultation</span>
                </li>
              </ul>
            </div>
            <div className="early-access-image">
              <img src="Innovation-Lightbulb.png" alt="Early Access" />
            </div>
          </div>
          
          <div className="early-access-form">
            <div className="form-header">
              <h3>Request Early Access</h3>
              <p>Limited spots available. Fill out the form below to secure your place.</p>
            </div>
            
            {submissionMessage && (
              <div className={`submission-message ${submissionMessage.includes('Thank you') ? 'success' : 'error'}`}>
                {submissionMessage}
              </div>
            )}
            
            <div className="form-row">
              <div className="form-group">
                <label className="form-label">First Name*</label>
                <input 
                  type="text" 
                  placeholder="John" 
                  className="form-input"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label className="form-label">Last Name*</label>
                <input 
                  type="text" 
                  placeholder="Smith" 
                  className="form-input"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>
            
            <div className="form-group">
              <label className="form-label">Work Email*</label>
              <input 
                type="email" 
                placeholder="john@company.com" 
                className="form-input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            
            <div className="form-row">
              <div className="form-group">
                <label className="form-label">Company*</label>
                <input 
                  type="text"
                  placeholder="Company Name" 
                  className="form-input"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label className="form-label">Role*</label>
                <input 
                  type="text" 
                  placeholder="Your Position" 
                  className="form-input"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                />
              </div>
            </div>
            
            <div className="form-group">
              <label className="form-label">Additional Details (Optional)</label>
              <textarea 
                placeholder="Tell us more about your needs and how you plan to use Harmoni..." 
                className="form-input form-textarea"
                rows={4}
                value={additionalDetails}
                onChange={(e) => setAdditionalDetails(e.target.value)}
              />
            </div>
            
            <button 
              className="cta-button form-submit" 
              onClick={handleEarlyAccess}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Request Early Access'}
            </button>
            
            <p className="form-disclaimer">
              By submitting this form, you agree to our <a href="#">Privacy Policy</a> and <a href="#">Terms of Service</a>.
            </p>
          </div>
        </div>
      </div>

      <footer className="footer">
        <div className="footer-top">
          <div className="footer-content">
            <div className="footer-section brand-section">
              <div className="footer-logo">
                <img src="Logo.png" alt="Harmoni Logo" className='logo-image' />
                Harmoni
              </div>
              <p>Transform your company's innovation process with our AI-powered platform.</p>
              <div className="social-links">
                <a href="#" className="social-link">
                  <i className="fab fa-linkedin"></i>
                </a>
                <a href="#" className="social-link">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="#" className="social-link">
                  <i className="fab fa-facebook"></i>
                </a>
                <a href="#" className="social-link">
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>
            
            <div className="footer-section">
              <h3>Product</h3>
              <a href="#features" onClick={(e) => handleNavClick(e, 'features')}>Features</a>
              <a href="#benefits" onClick={(e) => handleNavClick(e, 'benefits')}>Benefits</a>
              <a href="#testimonials" onClick={(e) => handleNavClick(e, 'testimonials')}>Testimonials</a>
              <a href="#roi" onClick={(e) => handleNavClick(e, 'roi')}>ROI</a>
            </div>
            
            <div className="footer-section">
              <h3>Company</h3>
              <a href="/">About Us</a>
              <a href="/">Contact</a>
              <a href="/">Privacy Policy</a>
              <a href="/">Terms of Service</a>
            </div>
            
            <div className="footer-section newsletter-section">
              <h3>Stay Updated</h3>
              <p>Subscribe to our newsletter for the latest updates and innovation tips.</p>
              <div className="newsletter-form">
                <input 
                  type="email" 
                  placeholder="Your email address" 
                  className="newsletter-input" 
                />
                <button className="newsletter-button">Subscribe</button>
              </div>
            </div>
          </div>
        </div>
        
        <div className="footer-bottom">
          <div className="footer-bottom-content">
            <p>&copy; {new Date().getFullYear()} Harmoni. All rights reserved.</p>
            <div className="footer-bottom-links">
              <a href="/">Privacy</a>
              <a href="/">Terms</a>
              <a href="/">Cookies</a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;